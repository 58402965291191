import { getProfile } from 'actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Profile } from 'reducers';
import { StoreState } from 'store';

const useProfile = (): UseProfile => {
  const profile = useSelector<StoreState, Profile | undefined>(state => state.interface.profile);
  const dispatch = useDispatch();

  const result = profile ? { ...profile } : undefined;

  useEffect(() => {
    if (profile) {
      return;
    }

    dispatch(getProfile());
  }, [profile, dispatch]);

  return { profile: result };
};

type UseProfile = {
  profile?: Profile;
};

export default useProfile;

import React, { FC, memo } from 'react';
import FileListItem from './FileListItem';
import './file-list.scss';

const FileList: FC<Props> = ({ files, onClickRemove }) => {
  return (
    <ul className="file-list">
      {files.map(item => (
        <FileListItem key={item.lastModified} file={item} onClickRemove={onClickRemove(item)} />
      ))}
    </ul>
  );
};

type Props = {
  files: File[];
  onClickRemove: (file: File) => () => void;
};

export default memo(FileList);

import React, { FC, memo } from 'react';
import clsx from 'clsx';
import Message, { MessageType } from './Message';
import './message-feed.scss';

const MessageFeed: FC<Props> = ({ messages, className = '' }) => {
  const classes = clsx('message-feed', className);

  return (
    <div className={classes}>
      {messages.map(message => (
        <Message key={message?.createdAt} {...message} />
      ))}
    </div>
  );
};

type Props = {
  className?: string;
  messages: MessageType[];
};

export default memo(MessageFeed);

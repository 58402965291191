import { useDispatch } from 'react-redux';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { t } from 'i18next';
import useToggleList from 'hooks/useToggleList';
import validatePassword from 'utils/validatePassword';
import { changePassword } from 'actions';

const validate = (values: State) => {
  const { newPassword, oldPassword, repeatNewPassword } = values;
  let errors = {};

  if (newPassword !== repeatNewPassword) {
    errors = { repeatNewPassword: t('login:not_matching_passwords') };
  }

  errors = {
    ...errors,
    ...validatePassword(newPassword, 'newPassword'),
    ...validatePassword(oldPassword, 'oldPassword'),
    ...validatePassword(repeatNewPassword, 'repeatNewPassword'),
  };

  return errors;
};

const initialToggles = {
  oldPassword: false,
  newPassword: false,
  repeatNewPassword: false,
};

const useChangePassword: UseChangePassword = ({ success }) => {
  const { items: visible, toggle } = useToggleList(initialToggles);
  const dispatch = useDispatch();
  const formik = useFormik<State>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
    validate,
    onSubmit: values => {
      const { newPassword, oldPassword } = values;

      dispatch(
        changePassword(
          {
            newPassword,
            password: oldPassword,
          },
          () => {
            formik.setValues({
              oldPassword: '',
              newPassword: '',
              repeatNewPassword: '',
            });
            success();
          }
        )
      );
    },
  });
  const { handleChange, handleBlur, handleSubmit, values, errors, touched } = formik;

  return { formik, visible, toggle, handleBlur, handleChange, handleSubmit, values, errors, touched };
};

type State = {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};
type Arguments = {
  success: () => void;
};

type UseChangePassword = (args: Arguments) => {
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
  handleSubmit: (e?: any) => void;
  touched: FormikTouched<State>;
  errors: FormikErrors<State>;
  values: State;
  visible: Record<keyof State, boolean>;
  toggle: (key: 'oldPassword' | 'newPassword' | 'repeatNewPassword') => () => void;
};

export default useChangePassword;

import { AxiosResponse } from 'axios';

export default (response: AxiosResponse, title: string) => {
  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  const url = window.URL.createObjectURL(blob);
  const element = document.createElement('a');

  element.href = url;
  element.setAttribute('download', title);
  document.body.appendChild(element);
  element.click();
  element.parentNode?.removeChild(element);
  window.URL.revokeObjectURL(url);
};

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import RouteIndex from 'RouteIndex';

import AuthNotification from 'components/AuthNotification';
import Reference from 'components/Reference';
import InputLabel from 'components/InputLabel';
import Input from 'components/Input';
import StartLayout from 'components/StartLayout';
import Headline from 'components/Headline';
import Button from 'components/Button';
import usePasswordRecovery from './usePasswordRecovery';

import './password-recovery.scss';

const PasswordRecovery = () => {
  const { t } = useTranslation();
  const [{ email, touched, errors, step }, { onChange, onBlur, onSubmit }] = usePasswordRecovery();
  const rootclass = clsx(
    'password-recovery',
    `password-recovery--${step === 'notification' ? 'notification' : 'form'}`
  );

  let content;

  if (step === 'notification') {
    content = (
      <AuthNotification
        title={t('login:check_inbox_title')}
        text={t('login:reset_password_text_1', { email })}
        referenceHref={RouteIndex.login}
        referenceText={t('login:back_to_page')}
        referenceLink={t('login:back_to_login')}
      />
    );
  } else {
    content = (
      <>
        <Headline className="password-recovery__headline">{t('login:pass_recovery_headline')}</Headline>
        <span className="password-recovery__text">{t('login:pass_recovery_text')}</span>

        <InputLabel text={t('login:email')} required />
        <Input
          width="fill"
          name="email"
          value={email}
          placeholder={t('login:form_label_email')}
          className="password-recovery__input"
          error={(touched.email && errors.email) || ''}
          onChange={onChange}
          onBlur={onBlur}
        />

        <Button className="password-recovery__button" width="fill" onClick={onSubmit}>
          {t('common:send')}
        </Button>

        <Reference text={t('login:back_to_page')} link={t('login:back_to_login')} href={RouteIndex.login} />
      </>
    );
  }

  return (
    <div className={rootclass}>
      <StartLayout
        contentWrapperClass="password-recovery__content-wrapper"
        contentClass="password-recovery__content-container"
      >
        {content}
      </StartLayout>
    </div>
  );
};

export default memo(PasswordRecovery);

import { resetPassword } from 'actions';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteIndex from 'RouteIndex';
import validatePassword from 'utils/validatePassword';

const validate = (values: State) => {
  let errors = {};

  errors = {
    ...validatePassword(values.password),
    ...validatePassword(values.repeatPassword, 'repeatPassword'),
  };

  if (values.password !== values.repeatPassword) {
    errors = { ...errors, repeatPassword: t('login:not_matching_passwords') };
  }

  return errors;
};

const useResetPassword = (): UseResetPassword => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validate,
    onSubmit: values => {
      const { password } = values;
      const hash = params.get('hash');
      const email = params.get('email');

      if (!email || !hash) {
        // todo: error handling
        return;
      }

      dispatch(
        resetPassword({ email, hash, newPassword: password }, () => {
          navigate(RouteIndex.login);
        })
      );
    },
  });

  return [
    {
      ...formik.values,
      touched: formik.touched,
      errors: formik.errors,
    },
    {
      onChange: formik.handleChange,
      onSubmit: formik.handleSubmit,
      onBlur: formik.handleBlur,
    },
  ];
};

type State = {
  password: string;
  repeatPassword: string;
};

type UseResetPassword = [
  State & {
    touched: FormikTouched<State>;
    errors: FormikErrors<State>;
  },
  {
    onChange: (e: any) => void;
    onSubmit: () => void;
    onBlur: (e: React.FocusEvent<any, Element>) => void;
  }
];

export default useResetPassword;

import React, { FC, memo, ReactChild } from 'react';
import clsx from 'clsx';
import './button-base.scss';

const ButtonBase: FC<Props> = ({ disabled = false, children, className = '', onClick, type }) => {
  const classes = clsx('button-base', className);

  return (
    <button type={type} className={classes} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

type Props = {
  children?: ReactChild | ReactChild[] | string | null;
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: 'button' | 'submit';
};

export default memo(ButtonBase);

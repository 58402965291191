import React, { FC, memo, ReactElement } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import './dropzone.scss';

const Dropzone: FC<Props> = ({ maxSize, className = '', onDrop, children }) => {
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, maxSize });
  const classes = clsx('dropzone', className);

  return (
    <div className={classes} {...getRootProps()}>
      <input {...getInputProps()} />
      {typeof children === 'function' ? children({ open }) : children}
    </div>
  );
};

type Props = {
  children?: ReactElement | string | (({ open }: { open: () => void }) => ReactElement);
  className?: string;
  maxSize?: number;
  onDrop: (files: File[]) => void;
};

export default memo(Dropzone);

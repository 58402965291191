import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setNavbarActions, setviewTitle } from 'actions';
import TableCell from 'components/Table/items/TableCell';
import TableRow from 'components/Table/items/TableRow';
import Table from 'components/Table';
import Flex from 'components/Flex';
import Chip from 'components/Chip';
import mockedData from './mockedInvoices';
import InvoiceFile from './InvoiceFile';
import './invoices.scss';
import TableLayout from 'components/TableLayout';

const Invoices: FC = () => {
  const { t } = useTranslation('invoices');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setviewTitle(t('navbar:view_title_invoices')));
    dispatch(setNavbarActions({}));
  }, []);

  const columns = {
    invoiceNr: t('label_invoice_no'),
    period: t('label_period'),
    date: t('label_issued'),
    paymentTime: t('label_deadline'),
    sum: t('label_price'),
    currency: t('label_currency'),
    files: t('label_files'),
  };

  return (
    <TableLayout
      className="invoices"
      afterTable={
        <Flex width="fill" justify="end" align="center" className="invoices__stats">
          {t('invoices_registered')}: <span className="stats__number"> {mockedData.length}</span>
        </Flex>
      }
    >
      <Table columns={Object.values(columns)}>
        {mockedData.map(invoice => {
          const { id, invoiceNr, period, date, paymentTime, sum, currency, files } = invoice;

          return (
            <TableRow key={id}>
              <TableCell>
                <Chip value={invoiceNr} />
              </TableCell>
              <TableCell value={period} />
              <TableCell value={date} />
              <TableCell value={paymentTime} />
              <TableCell value={sum} />
              <TableCell value={currency} />
              <TableCell>
                <InvoiceFile id={id} name={files} />
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </TableLayout>
  );
};

export default Invoices;

import React, { FC, ReactNode, useRef, useEffect } from 'react';

type ClickAwayProps = {
  children: ReactNode | [ReactNode];
  onClickAway?: (e: MouseEvent) => void;
};

const ClickAway: FC<ClickAwayProps> = ({ children, onClickAway }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref?.current && !ref?.current?.contains(event.target as Node) && onClickAway) {
        onClickAway(event);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickAway]);

  return <div ref={ref}>{children}</div>;
};

export default ClickAway;

import { useCallback, useState } from 'react';

const useToggleList = <T extends Record<string, boolean>>(initialValues: T): UseToggleList<T> => {
  const [items, setItems] = useState<T>(initialValues);

  const toggle = useCallback(
    (key: keyof T) => () => {
      setItems(prev => ({ ...prev, [key]: !prev[key] }));
    },
    []
  );

  return {
    items,
    toggle,
  };
};

type UseToggleList<T> = {
  items: T;
  toggle: (key: keyof T) => () => void;
};

export default useToggleList;

import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const usePagination = ({ itemCount }: Arguments): UsePagination => {
  const [state, setState] = useState<State>({
    currentPage: 1,
    limit: 200,
  });
  const { currentPage } = state;
  const pageCount = Math.ceil(itemCount / state.limit);
  const offset = state.limit * (state.currentPage - 1);

  const firstPage = useCallback(() => {
    setState(prev => ({ ...prev, currentPage: 1 }));
  }, []);

  const nextPage = useCallback(() => {
    if (currentPage + 1 <= pageCount) {
      setState(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  }, [currentPage, pageCount]);

  const previousPage = useCallback(() => {
    if (currentPage - 1 >= 1) {
      setState(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  }, [currentPage]);

  const lastPage = useCallback(() => {
    setState(prev => ({ ...prev, currentPage: pageCount }));
  }, [pageCount]);

  const changeLimit = useCallback((limit: number) => {
    setState(prev => ({ ...prev, currentPage: 1, limit }));
  }, []);

  const setPage = useDebouncedCallback(value => {
    const page = value?.target?.value || 1;
    setState(prev => ({ ...prev, currentPage: page }));
  }, 500);

  return [
    { ...state, pageCount, offset },
    { lastPage, nextPage, previousPage, firstPage, changeLimit, setPage },
  ];
};

type Arguments = {
  itemCount: number;
};
type State = {
  currentPage: number;
  limit: number;
};
type UsePagination = [
  State & {
    pageCount: number;
    offset: number;
  },
  {
    lastPage: () => void;
    nextPage: () => void;
    previousPage: () => void;
    firstPage: () => void;
    changeLimit: (limit: number) => void;
    setPage: (event: any) => void;
  }
];

export default usePagination;

import React, { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronIcon } from 'assets/images/chevron.svg';

type RecordAmountSelectControllerProps = {
  isVisible: boolean;
  onClick: () => void;
  onSelect: (option: number) => void;
  selectedOption: number;
  view: string;
};

const SelectController: FC<RecordAmountSelectControllerProps> = ({ isVisible, onClick, selectedOption, view }) => {
  const { t } = useTranslation('common');
  const controllerValue = t(`pagination_${view}_show_cases`, { count: selectedOption });
  const iconClasses = clsx('controller__icon', { 'controller__icon--rotated': isVisible });

  return (
    <div className="record-amount-select__controller-wrapper">
      <button type="button" className="record-amount-select__controller" onClick={onClick}>
        <span className="controller__value">{controllerValue}</span>
        <ChevronIcon className={iconClasses} />
      </button>
    </div>
  );
};

export default SelectController;

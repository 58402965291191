import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store';
import axios from 'axios';

import { HOST } from 'api/client';
import { getMessagesList, setNavbarActions, setviewTitle } from 'actions';
import { Message } from 'reducers';

import { usePagination } from 'components/Pagination';
import Pagination from 'components/Pagination';
import TableLayout from 'components/TableLayout';
import FileUpload from 'components/FileUpload';
import InputLabel from 'components/InputLabel';
import Divider from 'components/Divider';
import Button from 'components/Button';
import Input from 'components/Input';
import Flex from 'components/Flex';
import FileList from 'components/FileList';
import MessageFeed from './MessageFeed';

import './messages.scss';

const MAX_FILE_SIZE = 30 * 1024 * 1024;

const Messages: FC = () => {
  const { t } = useTranslation('messages');
  const dispatch = useDispatch();

  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [dataFiles, setDataFiles] = useState<File[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const messagesList = useSelector<StoreState, Message[]>(state => state.interface.messagesList?.messages);
  const itemCount = useSelector<StoreState, number>(state => state?.interface?.messagesList?.count);

  const [
    { currentPage, limit, pageCount, offset },
    { lastPage, nextPage, previousPage, firstPage, changeLimit, setPage },
  ] = usePagination({
    itemCount,
  });

  const fetchMessages = () =>
    getMessagesList({
      params: {
        filter: {
          limit,
          offset,
        },
      },
    });

  useEffect(() => {
    dispatch(setNavbarActions({ label: 'goBack' }));
    dispatch(setviewTitle(t('navbar:view_title_messages')));
    dispatch(fetchMessages());
  }, []);

  useEffect(() => {
    dispatch(fetchMessages());
  }, [limit, offset]);

  const sendMessage = useCallback(() => {
    if (!title && !message) return;

    setLoading(true);
    const formData = new FormData();
    dataFiles.forEach(file => formData.append('file', file));
    formData.append('title', JSON.stringify(title));
    formData.append('message', JSON.stringify(message));

    axios({
      method: 'post',
      url: `${HOST}/company/group/client/message`,
      data: formData,
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then(() => {
        setTitle('');
        setMessage('');
        setDataFiles([]);
        setLoading(false);
        dispatch(fetchMessages());
      })
      .catch(error => {
        console.error(error);
      });
  }, [dataFiles, title, message]);

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    switch (e.target.tagName) {
      case 'INPUT':
        setTitle(e.target.value);
        break;
      case 'TEXTAREA':
        setMessage(e.target.value);
        break;

      default:
        break;
    }
  };

  const drop = (files: File[]) => {
    setDataFiles([...dataFiles, ...files]);
  };

  const remove = (file: File) => () => {
    const filteredFiles = dataFiles.filter(filteredFile => filteredFile.lastModified !== file.lastModified);
    setDataFiles(filteredFiles);
  };

  return (
    <TableLayout
      className="messages-wrapper"
      additionalcontent={
        <Pagination
          itemCount={itemCount}
          currentPage={currentPage}
          limit={limit}
          pageCount={pageCount}
          view="messages"
          onChangeLimit={changeLimit}
          onChangePage={setPage}
          onClickFirst={firstPage}
          onClickLast={lastPage}
          onClickNext={nextPage}
          onClickPrevious={previousPage}
        />
      }
    >
      <div className="messages">
        <Flex>
          <div className="messages__container">
            <InputLabel text={t('title')} />
            <Input
              width="fill"
              value={title}
              placeholder={t('title_placeholder')}
              className="messages__title"
              onChange={handleChange}
            />
            <InputLabel text={t('content')} />
            <textarea
              className="messages__textarea"
              value={message}
              placeholder={t('content_placeholder')}
              onChange={handleChange}
            />

            <Flex justify="end">
              <Button width="auto" height="compact" onClick={sendMessage} loading={isLoading}>
                <span>{t('send_message')}</span>
              </Button>
            </Flex>
          </div>

          <div className="messages__upload-container">
            <InputLabel text={t('attachments')} />
            <FileUpload maxSize={MAX_FILE_SIZE} className="messages__file-upload" onDrop={drop} />
            <FileList files={dataFiles} onClickRemove={remove} />
          </div>
        </Flex>

        <Divider />

        <Flex>
          <MessageFeed messages={messagesList || []} className="messages__feed" />
          <div className="messages__empty-space" />
        </Flex>
      </div>
    </TableLayout>
  );
};

export default Messages;

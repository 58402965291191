import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonEn from './assets/locales/en/common.json';
import commonPl from './assets/locales/pl/common.json';
import loginEn from './assets/locales/en/login.json';
import loginPl from './assets/locales/pl/login.json';
import invoicesPl from './assets/locales/pl/invoices.json';
import invoicesEn from './assets/locales/en/invoices.json';
import navbarEn from './assets/locales/en/navbar.json';
import navbarPl from './assets/locales/pl/navbar.json';
import profilePl from './assets/locales/pl/profile.json';
import profileEn from './assets/locales/en/profile.json';
import sidebarEn from './assets/locales/en/sidebar.json';
import sidebarPl from './assets/locales/pl/sidebar.json';
import messagesEn from './assets/locales/en/messages.json';
import messagesPl from './assets/locales/pl/messages.json';
import notificationsPl from './assets/locales/pl/notifications.json';
import notificationsEn from './assets/locales/en/notifications.json';
import clientsPl from './assets/locales/pl/clients.json';
import clientsEn from './assets/locales/en/clients.json';

const resources = {
  pl: {
    common: commonPl,
    login: loginPl,
    invoices: invoicesPl,
    profile: profilePl,
    navbar: navbarPl,
    sidebar: sidebarPl,
    messages: messagesPl,
    notifications: notificationsPl,
    clients: clientsPl,
  },
  en: {
    common: commonEn,
    login: loginEn,
    invoices: invoicesEn,
    profile: profileEn,
    navbar: navbarEn,
    sidebar: sidebarEn,
    messages: messagesEn,
    notifications: notificationsEn,
    clients: clientsEn,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'pl',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
    wait: false,
  },
});

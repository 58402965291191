import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from 'components/Sidebar';
import Navbar from 'components/Navbar';

import './layout.scss';

const Layout: FC = () => (
  <div className="layout">
    <Sidebar />
    <main>
      <Navbar />
      <Outlet />
    </main>
  </div>
);

export default Layout;

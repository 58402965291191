import React, { FC } from 'react';

type Props = {
  columns: string[];
};

const TableHead: FC<Props> = ({ columns }) => {
  return (
    <thead className="table__head">
      <tr>
        {columns.map((col: string, i: number) => (
          <th key={i}>{col}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

class Route {
  public readonly path: string;

  constructor(path: string) {
    this.path = path;
  }

  get = (params: Record<string, string>): string => {
    let result = this.path;

    Object.keys(params).forEach(key => {
      const re = new RegExp(`:${key}`, 'g');
      result = result.replace(re, params[key]);
    });

    return result;
  };

  static create = (path: string): Route => {
    return new Route(path);
  };
}

export default {
  profile: '/',
  login: '/login',
  passwordRecovery: '/recover-password',
  invoices: '/invoices',
  resetPassword: '/password/reset',
  messages: '/messages',
  clients: '/clients',
};

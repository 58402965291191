import { useState } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { requestResetPassword } from 'actions';
import validateEmail from 'utils/validateEmail';

const validate = (values: State) => {
  let errors = {};

  if (!values.email) {
    errors = { email: t('login:cannot_be_empty') };
  } else if (!validateEmail(values.email)) {
    errors = { email: t('login:invalid_email') };
  }

  return errors;
};

const usePasswordRecovery = (): UsePasswordRecovery => {
  const [step, setStep] = useState<Step>('form');
  const dispatch = useDispatch();
  const formik = useFormik<State>({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: values => {
      const { email } = values;

      dispatch(
        requestResetPassword({ email }, () => {
          setStep('notification');
        })
      );
    },
  });

  return [
    { ...formik.values, errors: formik.errors, touched: formik.touched, step },
    {
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      onSubmit: formik.handleSubmit,
    },
  ];
};

type State = {
  email: string;
};
type Step = 'form' | 'notification';

type UsePasswordRecovery = [
  State & {
    errors: FormikErrors<{ email: string }>;
    touched: FormikTouched<{ email: boolean }>;
    step: Step;
  },
  {
    onChange: (e: any) => void;
    onBlur: (e: React.FocusEvent<any, Element>) => void;
    onSubmit: () => void;
  }
];

export default usePasswordRecovery;

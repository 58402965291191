import { t } from 'i18next';

const validate = (values: Record<string, string>) => {
  let errors = {};

  if (!values.groupPassword) {
    errors = { groupPassword: t('common:cannot_be_empty') };
  }

  if (!values.groupLogin) {
    errors = { ...errors, groupLogin: t('common:cannot_be_empty') };
  }

  return errors;
};

export default validate;

import React, { FC, memo, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Button from 'components/Button';
import './reference.scss';

const Reference: FC<Props> = ({ text, link, href, onClickReference, className = '', onClick }) => {
  const classes = clsx('reference', className);

  return (
    <span className={classes}>
      {text}{' '}
      {onClick && (
        <Button width="fill" onClick={onClick}>
          {link}
        </Button>
      )}
      <Link to={href} onClick={onClickReference} className="reference__link">
        {link}
      </Link>
    </span>
  );
};

type Props = {
  text: string;
  link: string;
  href: string;
  className?: string;
  onClickReference?: React.MouseEventHandler<HTMLAnchorElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export default memo(Reference);

import React, { FC, memo, MouseEventHandler, ReactNode } from 'react';

import Reference from 'components/Reference';
import Headline from 'components/Headline';
import Button from 'components/Button';

import './auth-notification.scss';

const AuthNotification: FC<Props> = ({
  title,
  text,
  referenceText = '',
  referenceLink = '',
  referenceHref = '',
  onClick,
  buttonLabel,
  onClickReference,
}) => (
  <div className="auth-notification">
    <Headline className="auth-notification__headline">{title}</Headline>
    {text && <p className="auth-notification__text">{text}</p>}

    {referenceHref && (
      <Reference
        className="auth-notification__link"
        text={referenceText}
        link={referenceLink}
        href={referenceHref}
        onClickReference={onClickReference}
      />
    )}

    {buttonLabel && onClick && (
      <Button className="auth-notification__button" width="fill" onClick={onClick}>
        {buttonLabel}
      </Button>
    )}
  </div>
);

type Props = {
  title: string;
  text?: string;
  referenceText?: string;
  referenceLink?: string;
  referenceHref?: string;
  buttonLabel?: ReactNode;
  onClickReference?: React.MouseEventHandler<HTMLAnchorElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export default memo(AuthNotification);

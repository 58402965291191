import React, { ChangeEvent, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Month } from 'components/MonthSelector/months';

import InputLabel from 'components/InputLabel';
import Checkbox from 'components/Checkbox';
import MonthSelector from 'components/MonthSelector';
import Button from 'components/Button';
import Input from 'components/Input';
import Flex from 'components/Flex';

import PlusIcon from 'assets/images/icon-plus.svg';
import SearchIcon from 'assets/images/icon-search.svg';

const ActionBar: FC<Props> = ({
  date,
  isActive,
  handlechangeSearch,
  handleChange,
  handleChangeDate,
  onClickAddPerson,
  search,
}) => {
  const { t } = useTranslation('common');

  return (
    <Flex className="clients__action-bar" justify="space-between" align="end">
      <Flex align="end">
        <Input
          className="action-bar__search-input"
          startIcon={<img src={SearchIcon} />}
          value={search}
          placeholder={t('search')}
          onChange={handlechangeSearch}
        />

        <Flex direction="column" className="action-bar__select-wrapper">
          <InputLabel text={t('insurance')} />
          <MonthSelector value={date} onSelect={handleChangeDate} />
        </Flex>

        <Flex>
          <Checkbox checked={isActive} className="action-bar__checkbox" onChange={handleChange} />
          <span>{t('active')}</span>
        </Flex>
      </Flex>

      <Button startIcon={<img src={PlusIcon} />} width="auto" onClick={onClickAddPerson}>
        <span>{t('new_person')}</span>
      </Button>
    </Flex>
  );
};

type Props = {
  date: Month;
  isActive: boolean;
  handlechangeSearch: (value: ChangeEvent<HTMLInputElement>) => void;
  handleChange: (value: boolean) => void;
  handleChangeDate: (date: Month) => void;
  onClickAddPerson: () => void;
  search: string;
};

export default memo(ActionBar);

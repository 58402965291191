import { AxiosResponse } from 'axios';
import { NavbarActionsTypes } from 'components/Navbar/NavActions/NavActions';
import { SnackbarKey } from 'notistack';

import api, {
  AddClientBody,
  AddPackageBody,
  DeletePackageBody,
  ClientsListBody,
  EditClientBody,
  GetClientBody,
  GetFileBody,
  LoginBody,
  MessagesListBody,
  ChangePasswordBody,
  RequestResetPasswordBody,
  ResetPasswordBody,
} from 'api/api';
import { Notification } from 'reducers';
import extractErrors from 'utils/extractErrors';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const LOGIN = 'LOGIN';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const GET_AVAILABLE_PACKAGES = 'GET_AVAILABLE_PACKAGES';
export const GET_MESSAGES_LIST = 'GET_MESSAGES_LIST';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_LOGIN_IN_PROGRESS = 'SET_LOGIN_IN_PROGRESS';
export const SET_NAVBAR_ACTIONS = 'SET_NAVBAR_ACTIONS';
export const SET_VIEW_TITLE = 'SET_VIEW_TITLE';

const handleErrors = (response: AxiosResponse, dispatch: any) => {
  const errors = extractErrors(response);

  errors.forEach((error: string) => {
    dispatch(enqueueSnackbar(new Notification(error, 'error')));
  });
};

export const setNavbarActions = (navbarActions: NavbarActionsTypes) => {
  return {
    type: SET_NAVBAR_ACTIONS,
    payload: { navbarActions },
  };
};

export const setviewTitle = (title: string) => {
  return {
    type: SET_VIEW_TITLE,
    payload: { viewTitle: title },
  };
};

export const setLoggedIn = (value: boolean) => {
  return {
    type: SET_LOGGED_IN,
    payload: value,
  };
};

export const enqueueSnackbar = (notification: Notification) => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
    },
  };
};

export const closeSnackbar = (key: SnackbarKey) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key,
  key,
});

export const removeSnackbar = (key: SnackbarKey) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const login = (body: LoginBody, callback?: () => void, error?: (e: string) => void) => {
  return (dispatch: any) => {
    dispatch({ type: SET_LOGIN_IN_PROGRESS, payload: true });
    api.login
      .call({ body })
      .then(({ data }) => {
        if (data.success) {
          const accessToken = data?.data?.accessToken;
          const refreshToken = data?.data?.refreshToken;

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          dispatch({ type: LOGIN });

          if (callback) {
            callback();
          }
        } else {
          handleErrors(data, dispatch);
          if (error) {
            error(data);
          }
        }
      })
      .catch(error => {
        const code = error?.response?.data?.errors?.credentials;
        return dispatch(enqueueSnackbar(new Notification(code, 'error')));
      })
      .finally(() => {
        dispatch({ type: SET_LOGIN_IN_PROGRESS, payload: false });
      });
  };
};

export const requestResetPassword = (body: RequestResetPasswordBody, callback: () => void) => {
  return (dispatch: any) => {
    api.requestResetPassword.call({ body }).then(response => {
      if (response.data.success) {
        callback();
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const resetPassword = (body: ResetPasswordBody, callback: () => void) => {
  return (dispatch: any) => {
    api.resetPassword.call({ body }).then(response => {
      if (response.data.success) {
        dispatch(enqueueSnackbar(new Notification('password_changed', 'success')));
        callback();
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const changePassword = (body: ChangePasswordBody, callback: (response: AxiosResponse) => void) => {
  return (dispatch: any) => {
    api.changePassword.call({ body }).then(response => {
      if (response.data.success) {
        dispatch(enqueueSnackbar(new Notification('password_changed', 'success')));
        callback && callback(response);
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};
export const getFile = (body: GetFileBody, callback?: (response: AxiosResponse) => void) => {
  return () => {
    const url = `/group/client/files/${encodeURIComponent(body.fileId)}`;
    api.getFile.set('path', url);
    api.getFile.call({ body }).then(response => {
      if (response.status === 200) {
        callback && callback(response);
      }
    });
  };
};

export const addClient = (body: AddClientBody, callback?: (response: AxiosResponse) => void) => {
  return (dispatch: any) => {
    api.addClient.call({ body }).then(response => {
      if (response.data.success) {
        callback && callback(response);
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const editClient = (body: EditClientBody, callback?: (response: AxiosResponse) => void) => {
  return (dispatch: any) => {
    const url = `/clients/group/client/${encodeURIComponent(body?.clientId || '')}`;
    delete body.clientId;
    api.editClient.set('path', url);
    api.editClient.call({ body }).then(response => {
      if (response.data.success) {
        callback && callback(response);
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const getClient = (body: GetClientBody, callback?: (response: AxiosResponse) => void) => {
  return (dispatch: any) => {
    const url = `/clients/group/client/${encodeURIComponent(body.id)}`;
    api.getClient.set('path', url);
    api.getClient.call({ body }).then(response => {
      if (response.status === 200) {
        callback && callback(response);
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const getClientsList = (body: ClientsListBody) => {
  return (dispatch: any) => {
    api.clients.call({ body }).then(response => {
      if (response.data.success) {
        dispatch({ type: GET_CLIENTS_LIST, payload: response.data.data });
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const availablePackages = () => {
  return (dispatch: any) => {
    api.availablePackages.call().then(response => {
      if (response.data.success) {
        dispatch({ type: GET_AVAILABLE_PACKAGES, payload: response.data.data.packages });
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const addPackage = (body: AddPackageBody, callback?: (response: AxiosResponse) => void) => {
  return (dispatch: any) => {
    api.addPackage.call({ body }).then(response => {
      if (response.data.success) {
        dispatch(enqueueSnackbar(new Notification('package_added', 'success')));
        callback && callback(response);
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const deletePackage = (body: DeletePackageBody, callback?: (response: AxiosResponse) => void) => {
  return (dispatch: any) => {
    const url = `/package/clients/group/${encodeURIComponent(body.clientId)}/${encodeURIComponent(body.packageId)}`;
    api.deletePackage.set('path', url);
    api.deletePackage.call({ body }).then(response => {
      if (response.data.success) {
        dispatch(enqueueSnackbar(new Notification('package_deleted', 'success')));
        callback && callback(response);
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const getMessagesList = (body: MessagesListBody) => {
  return (dispatch: any) => {
    api.messages.call({ body }).then(response => {
      if (response.data.success) {
        dispatch({ type: GET_MESSAGES_LIST, payload: response.data.data });
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

export const getProfile = () => {
  return (dispatch: any) => {
    api.profile.call().then(response => {
      if (response.data.success) {
        dispatch({ type: GET_PROFILE, payload: response.data.data });
      } else {
        handleErrors(response, dispatch);
      }
    });
  };
};

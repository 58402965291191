import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputLabel from 'components/InputLabel';
import Headline from 'components/Headline';
import Button from 'components/Button';
import Input from 'components/Input';
import EyeIcon from 'assets/images/icon-eye.png';
import ClosedEyeIcon from 'assets/images/icon-eye-closed.svg';
import useChangePassword from './useChangePassword';
import './change-password-modal.scss';

const ChangePasswordModal: FC<Props> = ({ open, close }) => {
  const { t } = useTranslation();
  const { handleChange, handleBlur, handleSubmit, touched, errors, values, visible, toggle } = useChangePassword({
    success: close,
  });
  const { oldPassword, newPassword, repeatNewPassword } = values;

  return (
    <Modal open={open} onClose={close} className="change-password-modal">
      <div className="change-password-modal__content">
        <Box>
          <Headline className="content__headline">{t('login:change_password_headline')}</Headline>

          <InputLabel text={t('login:old_password')} required />
          <Input
            width="fill"
            name="oldPassword"
            value={oldPassword}
            placeholder={t('login:old_password_placeholder')}
            className="content__input"
            error={(touched.oldPassword && errors.oldPassword) || ''}
            type={visible.oldPassword ? 'text' : 'password'}
            onBlur={handleBlur}
            onChange={handleChange}
            endIcon={
              <IconButton size="small" onClick={toggle('oldPassword')}>
                <img src={visible.oldPassword ? ClosedEyeIcon : EyeIcon} alt="" />
              </IconButton>
            }
          />

          <InputLabel text={t('login:new_password')} required />
          <Input
            width="fill"
            name="newPassword"
            value={newPassword}
            placeholder={t('login:enter_new_password')}
            className="content__input"
            error={(touched.newPassword && errors.newPassword) || ''}
            type={visible.newPassword ? 'text' : 'password'}
            onBlur={handleBlur}
            onChange={handleChange}
            endIcon={
              <IconButton size="small" onClick={toggle('newPassword')}>
                <img src={visible.newPassword ? ClosedEyeIcon : EyeIcon} alt="" />
              </IconButton>
            }
          />

          <InputLabel text={t('login:enter_repeat_new_password')} required />
          <Input
            width="fill"
            name="repeatNewPassword"
            value={repeatNewPassword}
            placeholder={t('login:enter_repeat_new_password_placeholder')}
            className="content__input"
            error={(touched.repeatNewPassword && errors.repeatNewPassword) || ''}
            type={visible.repeatNewPassword ? 'text' : 'password'}
            onBlur={handleBlur}
            onChange={handleChange}
            endIcon={
              <IconButton size="small" onClick={toggle('repeatNewPassword')}>
                <img src={visible.repeatNewPassword ? ClosedEyeIcon : EyeIcon} alt="" />
              </IconButton>
            }
          />

          <Button width="fill" className="content__button" onClick={() => handleSubmit()}>
            {t('login:change_password')}
          </Button>
          <Button width="fill" onClick={close} variant="ghost">
            {t('common:cancel')}
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

type Props = {
  open: boolean;
  close: () => void;
};

export default memo(ChangePasswordModal);

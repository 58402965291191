import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';

import Headline from 'components/Headline';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Input from 'components/Input';
import InputLabel from 'components/InputLabel';

import EyeIcon from 'assets/images/icon-eye.png';
import ClosedEyeIcon from 'assets/images/icon-eye-closed.svg';
import RouteIndex from 'RouteIndex';
import './login-form.scss';

const LoginForm: FC<Props> = ({
  groupLogin,
  groupPassword,
  errors,
  loading = false,
  touched,
  onClickLogin,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleVisibility = () => {
    setIsPasswordVisible(prev => !prev);
  };

  return (
    <form onSubmit={onClickLogin}>

      <Flex direction="column" className="login-form">
        <Headline className="login-form__headling login-form__headling--center">{t('login:panel_title')}</Headline>
        <Headline className="login-form__headling">{t('login:login')}</Headline>

        {/* <span className="alert__text">{t('login:migration_alert')}</span> */}

        <InputLabel required text={t('login:login_label')} />
        <Input
          name="groupLogin"
          className="login-form__input"
          width="auto"
          value={groupLogin}
          error={(touched.groupLogin && errors.groupLogin) || ''}
          placeholder={t('login:form_label_login')}
          onChange={onChange}
          onBlur={onBlur}
        />

        <InputLabel
          required
          text={t('login:password_label')}
          link={t('login:dont_remember_pass')}
          href={RouteIndex.passwordRecovery}
        />
        <Input
          name="groupPassword"
          className="login-form__input"
          width="fill"
          type={isPasswordVisible ? 'text' : 'password'}
          value={groupPassword}
          placeholder={t('login:form_label_password')}
          onChange={onChange}
          error={(touched.groupPassword && errors.groupPassword) || ''}
          onBlur={onBlur}
          endIcon={
            <IconButton size="small" onClick={toggleVisibility}>
              <img src={isPasswordVisible ? ClosedEyeIcon : EyeIcon} alt="" />
            </IconButton>
          }
        />

        <Button className="login-form__button" type='submit' width="fill" loading={loading} onClick={onClickLogin}>
          {t('login:login')}
        </Button>
      </Flex>
    </form>
  );
};

type Props = {
  groupLogin: string;
  groupPassword: string;
  errors: FormikErrors<{
    groupLogin: string;
    groupPassword: string;
  }>;
  touched: FormikTouched<{
    groupLogin: string;
    groupPassword: string;
  }>;
  loading: boolean;
  onClickLogin: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: (e: any) => void;
};

export default memo(LoginForm);

import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { SessionState } from 'reducers/session';
import { StoreState } from 'store';

const ProtectedRoute = ({ element, fallback }: Props) => {
  const location = useLocation();
  const session = useSelector<StoreState, SessionState>(state => state.session);

  if (!session.loggedIn) {
    return <Navigate to={fallback} state={{ from: location.pathname }} />;
  }

  return element;
};

type Props = {
  element: ReactElement;
  fallback: string;
};

export default memo(ProtectedRoute);

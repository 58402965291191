import React, { FC, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  value?: string | number;
};

const TableCell: FC<Props> = ({ children, className, value }) => {
  return <td className={className}>{value || children}</td>;
};

export default TableCell;

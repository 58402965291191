import React, { FC, memo, ReactNode } from 'react';
import clsx from 'clsx';
import './flex.scss';

const Flex: FC<Props> = ({
  align = 'start',
  justify = 'start',
  direction = 'row',
  component = 'div',
  flex,
  width,
  children,
  className = '',
}) => {
  const Component = component;

  const classes = clsx('flex', `align-${align}`, `justify-${justify}`, `direction-${direction}`, className, {
    'flex-1': flex,
    [`width-${width}`]: width,
  });

  return <Component className={classes}>{children}</Component>;
};

type Props = {
  align?: 'start' | 'center' | 'end';
  justify?: 'start' | 'center' | 'end' | 'space-between';
  direction?: 'row' | 'column';
  width?: 'fill';
  component?: 'div' | 'li';
  flex?: boolean;
  children?: ReactNode;
  className?: string;
};

export default memo(Flex);

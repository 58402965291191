import React from 'react';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#BBA07A',
    fontSize: '14px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#BBA07A',
  },
}));

export const LTooltip: React.FC<TooltipProps> = props => {
  const { children, ...rest } = props;
  return (
    <BootstrapTooltip {...rest}>
      <div>{children}</div>
    </BootstrapTooltip>
  );
};

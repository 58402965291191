import { setLoggedIn } from 'actions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SessionState } from 'reducers/session';
import { StoreState } from 'store';

const useSession = () => {
  const session = useSelector<StoreState, SessionState>(state => state.session);
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    dispatch(setLoggedIn(false));
  }, [dispatch]);

  const login = useCallback(() => {
    dispatch(setLoggedIn(true));
  }, [dispatch]);

  return { session, login, logout };
};

export default useSession;

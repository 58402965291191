import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { login } from 'actions';
import { StoreState } from 'store';

import RouteIndex from 'RouteIndex';
import validate from './validate';

const useLogin = (redirectTo?: string): UseLogin => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector<StoreState, boolean>(state => state.session.loginLoading);
  const formik = useFormik({
    initialValues: {
      groupLogin: '',
      groupPassword: '',
    },
    validate,
    onSubmit: values => {
      const { groupLogin, groupPassword } = values;
      dispatch(
        login({ login: groupLogin, password: groupPassword }, () => {
          navigate(redirectTo || RouteIndex.profile);
        })
      );
    },
  });

  return [
    { ...formik.values, errors: formik.errors, loading, touched: formik.touched },
    {
      handleChange: formik.handleChange,
      handleLogin: formik.handleSubmit,
      handleBlur: formik.handleBlur,
    },
  ];
};

type State = {
  groupLogin: string;
  groupPassword: string;
};

type UseLogin = [
  State & {
    loading: boolean;
    errors: FormikErrors<{
      groupLogin: string;
      groupPassword: string;
    }>;
    touched: FormikTouched<{
      groupLogin: string;
      groupPassword: string;
    }>;
  },
  {
    handleChange: React.ChangeEventHandler<HTMLInputElement>;
    handleLogin: () => void;
    handleBlur: (e: any) => void;
  }
];

export default useLogin;

import React, { memo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StartLayout from 'components/StartLayout/StartLayout';
import LoginForm from './LoginForm';
import useLogin from './useLogin';
import { StoreState } from 'store';
import RouteIndex from 'RouteIndex';
import './login.scss';

const Login = () => {
  const location = useLocation();
  const state = location.state as { from?: string };
  const loggedIn = useSelector<StoreState, boolean>(state => state.session.loggedIn);
  const [{ groupLogin, groupPassword, loading, errors, touched }, { handleChange, handleLogin, handleBlur }] = useLogin(
    state?.from
  );

  if (loggedIn) {
    return <Navigate to={RouteIndex.profile} />;
  }

  return (
    <div className="login">
      <StartLayout>
        <LoginForm
          touched={touched}
          groupLogin={groupLogin}
          groupPassword={groupPassword}
          errors={errors}
          loading={loading}
          onChange={handleChange}
          onClickLogin={handleLogin}
          onBlur={handleBlur}
        />
      </StartLayout>
    </div>
  );
};

export default memo(Login);

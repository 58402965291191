import React, { FC } from 'react';

type RecordAmountSelectOptionsProps = {
  onSelect: (option: number) => void;
  options: number[];
  isVisible: boolean;
};

const SelectOptions: FC<RecordAmountSelectOptionsProps> = ({ options, onSelect }) => {
  const handleSelect = (option: number) => () => onSelect(option);

  return (
    <ul className="record-amount-select__options">
      {options.map((option: number) => (
        <li key={option} className="record-amount-select__option-item" onClick={handleSelect(option)}>
          {option}
        </li>
      ))}
    </ul>
  );
};

export default SelectOptions;

const units = ['B', 'KB', 'MB', 'GB'];

export default (sizeToCalc: number) => {
  const calcSize = (size: number, depth = 0): [number, number] => {
    if (size / 1024 < 0.01) {
      return [size, depth];
    }
    return calcSize(size / 1024, depth + 1);
  };

  const [size, unitIdx] = calcSize(sizeToCalc);
  const unit = units[unitIdx];

  return `${size.toFixed(2)}${unit}`;
};

import React from 'react';
import useNotifier from 'hooks/useNotifier';
import './App.scss';
import { Router } from 'views/Router';

const App = () => {
  useNotifier();
  return (
    <div className="App">
      <Router/>
    
    </div>
  );
};

export default App;

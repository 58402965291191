/* eslint-disable no-case-declarations */
import { PayloadAction } from '@reduxjs/toolkit';

import {
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  GET_CLIENTS_LIST,
  GET_AVAILABLE_PACKAGES,
  GET_MESSAGES_LIST,
  GET_PROFILE,
  SET_NAVBAR_ACTIONS,
  SET_VIEW_TITLE,
} from 'actions';
import { NavbarActionsTypes } from 'components/Navbar/NavActions/NavActions';

type NotificationType = 'success' | 'error' | 'warning' | 'info';

export class Notification {
  message: string;
  type: NotificationType;
  key: any;
  options?: Record<string, any>;
  dismissed: boolean;

  constructor(message: string, type: NotificationType) {
    this.message = message;
    this.type = type;
    this.dismissed = false;
    this.key = new Date().getTime() + Math.random();
  }
}

type Address = {
  city: string;
  id: number;
  postcode: string;
  street: string;
};

export type Company = {
  id: number;
  name: string;
  nip: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  phone_1: string;
  phone_2: string;
  agreementType: string;
  keywords: string;
  notes: string;
  address: Address;
  userAttendant: any;
};

export type AvailablePackage = {
  activeFrom: string;
  activeTo: string;
  amount: number;
  amountUsed: number;
  clientId?: number;
  companyId: number;
  id: number;
  name: string;
  package: {
    id: number;
    name: string;
    status: string;
  };
  packageId: number;
  price: string;
  status: string;
};

export type BoughtPackage = {
  activeFrom?: string;
  activeTo?: string;
  amount?: number;
  amountUsed?: number;
  clientId?: number;
  companyId?: number;
  id?: number;
  name?: string;
  packageId?: number;
  price?: number | string;
  status?: string;
  usedCompanyPackageId?: number;
};

export type Client = {
  additionalNr?: number;
  PESEL: string;
  address: Address;
  boughtPackage: BoughtPackage;
  companyId: number;
  consents: null;
  dateOfBirth: null;
  email: string;
  id: number;
  name: string;
  phone: string;
  surname: string;
  companyName: string;
};

type File = {
  id: number;
  originalName: string;
  fileSize: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  direction: string;
  user: {
    id: number;
    name: string;
    surname: string;
    login: string;
    email: string;
    roles: string[];
  };
  userId: number;
};

export type Message = {
  id: number;
  title: string;
  message: string;
  createdAt: string;
  files: File[];
  creator: {
    name: string;
    surname: string;
  };
};

export type Profile = {
  PESEL: string | null;
  additionalNr: string;
  companyName: string | null;
  company: Company;
  email: string;
  id: number;
  name: string;
  phone: string | null;
  surname: string;
  login: string;
};

export type DefaultState = {
  availablePackages: AvailablePackage[];
  navbarActions: NavbarActionsTypes;
  clientsList: { clients: Client[]; count: number };
  messagesList: { messages: Message[]; count: number };
  notifications: Notification[];
  profile?: Profile;
  viewTitle: string;
};

const defaultState = {
  availablePackages: [],
  navbarActions: {},
  clientsList: { clients: [], count: 0 },
  messagesList: { message: [], count: 0 },
  notifications: [],
  profile: undefined,
  viewTitle: '',
};

const interfaceReducer = (state = defaultState, action: PayloadAction<DefaultState>) => {
  switch (action.type) {
    case GET_CLIENTS_LIST:
      const clientsList = action.payload;
      return { ...state, clientsList };

    case GET_AVAILABLE_PACKAGES:
      const availablePackages = action.payload;
      return { ...state, availablePackages };

    case GET_MESSAGES_LIST:
      return { ...state, messagesList: action.payload };

    case GET_PROFILE:
      const profile = action.payload;
      return { ...state, profile };

    case SET_NAVBAR_ACTIONS:
      const { navbarActions } = action.payload;
      return { ...state, navbarActions };

    case SET_VIEW_TITLE:
      const { viewTitle } = action.payload;
      return { ...state, viewTitle };

    case ENQUEUE_SNACKBAR:
      const notification = (action as any)?.notification as Notification;

      return {
        ...state,
        notifications: [...state.notifications, notification],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
      };

    case REMOVE_SNACKBAR:
      const key = (action as any)?.key;

      return {
        ...state,
        notifications: state.notifications.filter(notification => (notification as Notification)?.key !== key),
      };

    default:
      return { ...state };
  }
};

export default interfaceReducer;

import React, { FC, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';

import ClickAway from 'components/ClickAway';
import SelectController from './items/SelectController';
import SelectOptions from './items/SelectOptions';

import SelectProps from './Select.types';

import './select.scss';

const Select: FC<SelectProps> = ({
  className = '',
  classes = {
    controller: '',
    helperText: '',
    label: '',
    optionItem: '',
    optionLabel: '',
    options: '',
  },
  defaultValue = '',
  label = '',
  emptyListPlaceholder,
  onSelect,
  value,
  options = [],
  startIcon,
}) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selectedOption, setOption] = useState<React.ReactChild>(value || '');

  const rootClasses = clsx('select', className);

  useEffect(() => {
    if (value === '') setOption(defaultValue);
  }, [value]);

  const handleMenuOpen = () => {
    setVisible(true);
  };

  const handleMenuClose = () => {
    setVisible(false);
  };

  const handleSelect = (option: string) => {
    onSelect(option);
    setOption(option);
    handleMenuClose();
  };

  return (
    <div className={rootClasses}>
      <SelectController
        classes={classes}
        defaultValue={defaultValue}
        isVisible={isVisible}
        label={label}
        onClick={handleMenuOpen}
        onSelect={handleSelect}
        selectedOption={selectedOption}
        startIcon={startIcon}
      />

      <ClickAway onClickAway={handleMenuClose}>
        <CSSTransition in={isVisible} timeout={200} classNames="options-fade-animation" unmountOnExit>
          <SelectOptions
            options={options}
            emptyListPlaceholder={emptyListPlaceholder}
            classes={classes}
            onSelect={handleSelect}
            isVisible={isVisible}
          />
        </CSSTransition>
      </ClickAway>
    </div>
  );
};

export default Select;

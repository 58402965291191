import React, { FC, memo, useCallback } from 'react';
import clsx from 'clsx';
import './checkbox.scss';

const Checkbox: FC<Props> = ({ checked = false, className = '', onChange }) => {
  const rootClasses = clsx(
    'checkbox',
    {
      'checkbox--checked': checked,
    },
    className
  );
  const classes = clsx('checkbox__input');

  const handleChange = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);

  return (
    <div className={rootClasses}>
      <div className="checkbox__mark" />
      <input checked={checked} type="checkbox" onChange={handleChange} className={classes} />
    </div>
  );
};

type Props = {
  className?: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
};

export default memo(Checkbox);

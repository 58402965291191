import { AxiosResponse } from 'axios';

const extractErrors = (response: AxiosResponse): string[] => {
  const errors = response?.data?.errors || {};
  const values = Object.values(errors) as string[];

  return values;
};

export default extractErrors;

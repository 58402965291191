export type Month = {
  name: string;
  number: number;
  year: number;
  currentMonth?: boolean;
};

export const months = [
  { name: 'january', number: 1, year: 0 },
  { name: 'february', number: 2, year: 0 },
  { name: 'march', number: 3, year: 0 },
  { name: 'april', number: 4, year: 0 },
  { name: 'may', number: 5, year: 0 },
  { name: 'june', number: 6, year: 0 },
  { name: 'july', number: 7, year: 0 },
  { name: 'august', number: 8, year: 0 },
  { name: 'september', number: 9, year: 0 },
  { name: 'october', number: 10, year: 0 },
  { name: 'november', number: 11, year: 0 },
  { name: 'december', number: 12, year: 0 },
];

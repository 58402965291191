import React, { FC, memo, ReactNode } from 'react';
import clsx from 'clsx';
import './headline.scss';

const Headline: FC<Props> = ({ children, className = '' }) => {
  const classes = clsx('headline', className);

  return <h1 className={classes}>{children}</h1>;
};

type Props = {
  children?: ReactNode;
  className?: string;
};

export default memo(Headline);

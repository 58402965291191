import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import useToggle from 'hooks/useToggle';
import calcSize from 'utils/calcSize';
import downloadFile from 'utils/downloadFile';
import { getFile } from 'actions';

import Flex from 'components/Flex';
import Button from 'components/Button';
import FileDownload from 'components/FileDownload';

import IconAttachment from 'assets/images/icon-attachment.svg';

const Message: FC<Props> = ({ title, message, creator, createdAt, files = [] }) => {
  const { t } = useTranslation('messages');
  const [expanded, { toggle }] = useToggle();
  const dispatch = useDispatch();

  const created = dayjs(createdAt).format('DD/MM/YYYY hh:mm');

  const downloadAll = () => {
    files.forEach(({ originalName, id }) => {
      dispatch(
        getFile({ fileId: id, responseType: 'arraybuffer' }, response => {
          downloadFile(response, originalName);
        })
      );
    });
  };
  return (
    <div className="message-feed__message">
      <div className="message__container">
        <h3 className="message__title">{JSON.parse(title)}</h3>
        <Flex>
          <p className="message__content">{JSON.parse(message)}</p>
        </Flex>
      </div>
      <Flex justify="space-between" className="message__details">
        <Flex align="center">
          {files.length > 0 && (
            <>
              <img src={IconAttachment} className="message__attachment-icon" />
              <Button className="message__button" width="auto" variant="text" onClick={toggle}>
                <span className="details__text">
                  {t('attachments')} ({files.length})
                </span>
              </Button>
              <Button className="details__button" width="auto" variant="text" onClick={downloadAll}>
                {t('download_all')}
              </Button>
            </>
          )}
        </Flex>

        <Flex align="center">
          <span className="message__author details__text">{`${creator?.name} ${creator?.surname}`}</span>
          <span className="message__date">{created}</span>
        </Flex>
      </Flex>

      {expanded &&
        files.map(({ id, originalName, fileSize }) => (
          <FileDownload id={id} title={originalName} size={calcSize(fileSize)} key={id} />
        ))}
    </div>
  );
};

type Props = {
  title: string;
  message: string;
  createdAt: string;
  creator: {
    name: string;
    surname: string;
  };
  files?: any[];
};

export type MessageType = Props;

export default memo(Message);

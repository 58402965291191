import React from 'react';
import { useTranslation } from 'react-i18next';

import useSession from 'hooks/useSession';
import useToggle from 'hooks/useToggle';

import ButtonBase from 'components/ButtonBase';
import ChangePasswordModal from './items/ChangePasswordModal';
import NavLink from './items/NavLink';

import { ReactComponent as Logo } from 'assets/images/logo-white.svg';
import { ReactComponent as Applications } from 'assets/images/applications_icon.svg';
// import { ReactComponent as Building } from 'assets/images/building_icon.svg';
import { ReactComponent as Chat } from 'assets/images/chat_icon.svg';
import { ReactComponent as Settings } from 'assets/images/settings_icon.svg';
import { ReactComponent as User } from 'assets/images/user_icon.svg';
import { ReactComponent as Exit } from 'assets/images/exit_icon.svg';

import './sidebar.scss';

const Sidebar = () => {
  const { t } = useTranslation();
  const { logout } = useSession();
  const [open, { set }] = useToggle();

  const links = [
    {
      icon: <Applications className="sidebar__icon" />,
      label: t('sidebar:label_main_panel'),
      link: '/',
    },
    {
      icon: <User className="sidebar__icon" />,
      label: t('sidebar:label_clients'),
      link: '/clients',
    },
    // {
    //     icon: <Building className="sidebar__icon" />,
    //     label: t('sidebar:label_invoices'),
    //     link: '/invoices',
    // },
    {
      icon: <Chat className="sidebar__icon" />,
      label: t('sidebar:label_messages'),
      link: '/messages',
    },
  ];

  return (
    <aside className="sidebar">
      <div className="sidebar__content">
        <Logo className="sidebar__logo" />
        <menu className="sidebar__menu">
          {links.map(({ icon, label, link }) => (
            <NavLink key={label} label={label} to={link} icon={icon} />
          ))}

          <button className="sidebar__link" onClick={() => set(true)}>
            <Settings className="sidebar__icon" />
            <span className="link__label">{t('sidebar:label_change_password')}</span>
          </button>
        </menu>
      </div>

      <ButtonBase className="sidebar__logout" onClick={logout}>
        <Exit className="sidebar__icon" />
        <span className="link__label">{t('sidebar:label_logout')}</span>
      </ButtonBase>

      <ChangePasswordModal open={open} close={() => set(false)} />
    </aside>
  );
};

export default Sidebar;

import React, { FC, ReactElement } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import './navlink.scss';

const NavLink: FC<Props> = ({ icon, label, to }) => {
  return (
    <RouterLink
      to={to}
      className={({ isActive }) => (isActive ? 'sidebar__link sidebar__link--active' : 'sidebar__link')}
    >
      {icon}
      <span className="link__label">{label}</span>
    </RouterLink>
  );
};

type Props = {
  icon?: ReactElement;
  label?: string;
  to: string;
};

export default NavLink;

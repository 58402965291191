import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { getFile } from 'actions';
import downloadFile from 'utils/downloadFile';
import Flex from 'components/Flex';

import { ReactComponent as DownloadIcon } from 'assets/images/icon-download.svg';
import './file-download.scss';

const FileDownload: FC<Props> = ({ id, size, title, className = '', anchorClass = '', iconClass = '' }) => {
  const dispatch = useDispatch();

  const classes = clsx('file-download', className);
  const anchorClasses = clsx('file-download__title', 'file-download__button', anchorClass);
  const iconClasses = clsx('file-download__icon', iconClass);

  const fetchFile = () => {
    dispatch(
      getFile({ fileId: id, responseType: 'arraybuffer' }, response => {
        downloadFile(response, title);
      })
    );
  };

  return (
    <Flex align="center" className={classes}>
      <DownloadIcon className={iconClasses} />
      <button onClick={fetchFile} type="button" className={anchorClasses}>
        <span className="file-download__file-name">{title}</span>
        {size && <span className="file-download__file-size"> ({size})</span>}
      </button>
    </Flex>
  );
};

type Props = {
  id: number;
  size?: string;
  title: string;
  className?: string;
  anchorClass?: string;
  iconClass?: string;
};

export default memo(FileDownload);

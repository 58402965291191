const mockedData = [
  {
    id: 110,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 111,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 112,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 113,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 114,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 115,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 116,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 117,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 118,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 119,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 120,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 121,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 122,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 123,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 124,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 125,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 126,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
  {
    id: 127,
    invoiceNr: '053583/A/PL170720001/21',
    period: '10/2022',
    date: '13/05/2022',
    paymentTime: '14 dni',
    sum: '1 800,00 zł',
    currency: 'PLN',
    files: 'FakturaVAT_12312.pdf',
  },
];

export default mockedData;

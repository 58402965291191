import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { StoreState } from 'store';
import { useDispatch, useSelector } from 'react-redux';

import { availablePackages, addPackage, deletePackage } from 'actions';
import type { AvailablePackage, Client } from 'reducers';

import Button from 'components/Button';
import Select from 'components/Select';
import Flex from 'components/Flex';

import { ReactComponent as CloseIcon } from 'assets/images/icon-close.svg';
import './package-selector.scss';

type Props = {
  client?: Client;
  open: boolean;
  close: () => void;
  fetchClients: () => void;
};

const PackageSelector: FC<Props> = ({ client, open, close, fetchClients }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const clientPackages = useSelector<StoreState, AvailablePackage[]>(state => state.interface.availablePackages);
  const [selectedPackage, setPackage] = useState(client?.boughtPackage);

  useEffect(() => {
    dispatch(availablePackages());
  }, []);

  const removePackage = () => {
    if (client?.id && selectedPackage?.id) {
      setPackage({});
      dispatch(
        deletePackage({ clientId: client.id, packageId: selectedPackage.id }, () => {
          dispatch(fetchClients());
          close();
        })
      );
    }
  };

  const savePackage = () => {
    if (client?.id && selectedPackage?.id) {
      dispatch(
        addPackage({ clientId: client.id, packageId: selectedPackage.id }, () => {
          dispatch(fetchClients());
          close();
        })
      );
    }
  };

  const onSelect = (pkg: any) => {
    setPackage(pkg);
  };

  const isDisabled = {
    save: () => {
      if (selectedPackage?.id) return false;
      return true;
    },
    remove: () => {
      if (client?.boughtPackage?.id) return false;
      return true;
    },
  };

  return (
    <Modal open={open} onClose={close}>
      <Flex justify="center" align="center" className="package-selector-modal">
        <Box>
          <div className="package-selector-modal__content">
            <button className="package-selector-modal__close" onClick={close}>
              <CloseIcon />
            </button>

            <p>{t('active_pkg')}</p>
            <Select
              defaultValue={t('select_pkg_choose')}
              onSelect={onSelect}
              options={clientPackages}
              value={selectedPackage?.name || ''}
              emptyListPlaceholder={t('no_packages_available')}
            />

            <Flex direction="column" className="package-selector-modal__button-wrapper">
              <Button onClick={savePackage} width="fill" disabled={isDisabled.save()}>
                {t('save')}
              </Button>
              <Button onClick={removePackage} width="fill" disabled={isDisabled.remove()} variant="text">
                {t('remove_pkg')}
              </Button>
            </Flex>
          </div>
        </Box>
      </Flex>
    </Modal>
  );
};

export default PackageSelector;

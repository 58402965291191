import { PayloadAction } from '@reduxjs/toolkit';
import { LOGIN, SET_LOGGED_IN, SET_LOGIN_IN_PROGRESS } from 'actions';

const defaultState = {
  loginLoading: false,
  registerLoading: false,
  loggedIn: !!localStorage.getItem('accessToken'),
} as const;

export type SessionState = typeof defaultState;

const sessionReducer = (state = defaultState, action: PayloadAction<typeof defaultState>) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      return { ...state, loggedIn: action.payload };
    case SET_LOGIN_IN_PROGRESS:
      return { ...state, loginLoading: action.payload };
    case LOGIN:
      return { ...state, loggedIn: true };
    default:
      return state;
  }
};

export default sessionReducer;

export const saveToLocalStorage = (key: string, item: string): void => {
    localStorage.setItem(key, item)
}

export const getFromLocalStorage = (key: string): string | null => localStorage.getItem(key)

export const removeFromLocalStorage = (key: string): void => localStorage.removeItem(key)

export const refreshTokenKey = 'LEX_RT'
export const userActivityTimeKey = 'LEX_USER_ACTIVITY_TIME'

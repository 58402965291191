import React, { FC } from 'react';
import clsx from 'clsx';

import './chip.scss';

type Props = {
  className?: string;
  status?: 'active' | 'resignation' | 'in-progress';
  value: string;
};

const Chip: FC<Props> = ({ className, status, value }) => {
  const classes = clsx('chip', `chip--${status?.toLowerCase()}`, className, { 'chip--with-status': Boolean(status) });
  return <div className={classes}>{value}</div>;
};

export default Chip;

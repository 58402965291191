import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StoreState } from 'store';
import { debounce } from 'lodash';

import { getClientsList, setNavbarActions, setviewTitle } from 'actions';
import type { Client } from 'reducers';

import useToggle from 'hooks/useToggle';
import { usePagination } from 'components/Pagination';
import Pagination from 'components/Pagination';
import TableLayout from 'components/TableLayout';
import TableCell from 'components/Table/items/TableCell';
import TableRow from 'components/Table/items/TableRow';
import Button from 'components/Button';
import Table from 'components/Table';
import ActionBar from './ActionBar';
import NewMemberModal from './NewMemberModal';
import PackageSelector from './PackageSelector';

import { Month } from 'components/MonthSelector/months';

import PencilIcon from 'assets/images/icon-pencil.svg';
import DeleteIcon from 'assets/images/delete_icon.svg';
import './clients.scss';
import { ClipLoader } from 'react-spinners';
import { LTooltip as Tooltip } from 'components/Tooltip/Tooltip';
import DeletePackageModal from './DeletePackageModal';

const Clients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [date, setDate] = useState<Month | any>({});
  const [isActive, setActive] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [client, setClient] = useState<Client>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clientsList = useSelector<StoreState, Client[]>(state => state.interface?.clientsList.clients);
  const itemCount = useSelector<StoreState, number>(state => state.interface.clientsList.count);

  const [openMemberModal, { set: setMemberModal }] = useToggle(false);
  const [openPackageModal, { set: setPackageModal }] = useToggle(false);
  const [openDeletePackageModal, { set: setDeletePackageModal }] = useToggle(false);

  const [
    { currentPage, limit, pageCount, offset },
    { lastPage, nextPage, previousPage, firstPage, changeLimit, setPage },
  ] = usePagination({
    itemCount,
  });

  const setMemberOpen = useCallback(
    (value: boolean) => () => {
      setMemberModal(value);
    },
    [setMemberModal]
  );
  const setPackageOpen = useCallback(
    (client: any, value: boolean) => () => {
      setClient(client);
      setPackageModal(value);
    },
    [setPackageModal]
  );
  const setDeletePackageOpen = useCallback(
    (client: any, value: boolean) => () => {
      setClient(client);
      setDeletePackageModal(value);
    },
    [setDeletePackageModal]
  );
  const editPersonModal = (clientId: number) => {
    setMemberOpen(true)();
    navigate({
      pathname: '/clients',
      search: `?clientId=${clientId}`,
    });
  };

  const handleChange = (value: boolean) => {
    setActive(value);
  };

  const handleChangeDate = (month: Month) => {
    setDate(month);
  };

  const getDate = () => {
    if (!date?.name) return undefined;
    return `${date?.year}-${date?.number?.toString()?.length === 1 ? 0 : ''}${date?.number}`;
  };

  const columns = {
    surname: t('common:surname'),
    name: t('common:name'),
    additionalNumber: t('common:additional_number'),
    // address: t('common:address'),
    package: t('common:packet'),
    plan: '',
    actions: '',
  };

  const fetchClients = (searchValue?: string) => {
    setIsLoading(true);
    return getClientsList({
      params: {
        onlyActive: isActive,
        yearMonth: getDate(),
        search: searchValue || search,
        filter: {
          limit,
          offset,
        },
      },
    });
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    dispatch(fetchClients(event.target.value));
  };

  const changeSearch = useCallback(debounce(changeHandler, 500), [isActive]);

  const handlechangeSearch = (value: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value.target.value);
    changeSearch(value);
  };

  useEffect(() => {
    dispatch(setviewTitle(t('navbar:view_title_clients')));
    dispatch(setNavbarActions({}));
    dispatch(fetchClients());
  }, []);

  useEffect(() => {
    dispatch(fetchClients());
  }, [isActive, date, limit, offset]);

  useEffect(() => {
    setIsLoading(false);
  }, [clientsList]);

  // const getClientAddress = (city?: string, street?: string): string => {
  //     if (city && street) return `${city}, ${street}`;
  //     if (city && !street) return city;
  //     if (!city && street) return street;
  //     return '';
  // };

  const shouldShowIdsTooltip = (ids: Client) => {
    return Object.values(ids).filter(value => !!value).length > 0;
  };

  const tooltipComponent = (value: string, client: Client) => {
    return (
      <Tooltip
        arrow
        disableInteractive
        title={
          <>
            <>
              Imię: {client.name}
              <br />
              Nazwisko: {client.surname}
              <br />
              PESEL: {client.PESEL}
              <br />
              Numer dodatkowy: {client.additionalNr}
              <br />
              Ulica: {client.address?.street}
              <br />
              Kod pocztowy: {client.address?.postcode}
              <br />
              Miasto: {client.address?.city}
              <br />
              Adres e-mail: {client.email}
              <br />
              Telefon: {client.phone}
              <br />
              Miejsce pracy: {client.companyName}
              <br />
            </>
          </>
        }
      >
        <>{value}</>
      </Tooltip>
    );
  };

  return (
    <TableLayout
      className="clients"
      beforeTable={
        <ActionBar
          date={date}
          isActive={isActive}
          handlechangeSearch={handlechangeSearch}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          onClickAddPerson={setMemberOpen(true)}
          search={search}
        />
      }
      additionalcontent={
        <Pagination
          itemCount={itemCount}
          currentPage={currentPage}
          limit={limit}
          pageCount={pageCount}
          view="clients"
          onChangeLimit={changeLimit}
          onChangePage={setPage}
          onClickFirst={firstPage}
          onClickLast={lastPage}
          onClickNext={nextPage}
          onClickPrevious={previousPage}
        />
      }
    >
      {openMemberModal && (
        <NewMemberModal open={openMemberModal} close={setMemberOpen(false)} fetchClients={fetchClients} />
      )}
      {openPackageModal && (
        <PackageSelector
          client={client}
          open={openPackageModal}
          close={setPackageOpen({}, false)}
          fetchClients={fetchClients}
        />
      )}
      {openDeletePackageModal && (
        <DeletePackageModal
          client={client}
          open={openDeletePackageModal}
          close={setDeletePackageOpen({}, false)}
          fetchClients={fetchClients}
          clientName={client?.name || ''}
          clientSurname={client?.surname || ''}
        />
      )}
      {isLoading ? (
        <TableCell className="clients__loader">
          <ClipLoader size={70} color={'#26c8e9'} />
        </TableCell>
      ) : (
        <Table columns={Object.values(columns)}>
          {clientsList?.map((client: Client) => {
            const { additionalNr = '', id, surname, name, PESEL = '', address, boughtPackage } = client;
            // const clientAddress = getClientAddress(address?.city, address?.street);

            return (
              <TableRow key={id}>
                <TableCell>{tooltipComponent(surname, client)}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{additionalNr}</TableCell>
                {/* <TableCell>{clientAddress}</TableCell> */}
                <TableCell>
                  <span>{boughtPackage?.name}</span>
                  {boughtPackage?.name ? (
                    <IconButton size="small" onClick={setDeletePackageOpen(client, true)}>
                      <img src={DeleteIcon} />
                    </IconButton>
                  ) : ''}
                </TableCell>
                <TableCell>
                  <Button
                    className="clients__table-button"
                    width="auto"
                    variant="text"
                    onClick={setPackageOpen(client, true)}
                  >
                    {t('common:change')}
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => editPersonModal(id)}>
                    <img src={PencilIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      )}
    </TableLayout>
  );
};

export default Clients;

import React, { FC, ChangeEvent, FocusEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Client } from 'api/api';
import { availablePackages } from 'actions';

import InputLabel from 'components/InputLabel';
import Divider from 'components/Divider';
import Button from 'components/Button';
import Input from 'components/Input';
import Flex from 'components/Flex';
import Select from 'components/Select';
import { StoreState } from 'store';
import { AvailablePackage } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';

type ClientFormProps = {
  client: Client;
  close: () => void;
  touched: any;
  errors: any;
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: FocusEvent<any, Element>) => void;
  handleSubmit: () => void;
  handlePackageChange?: (packageId: number | undefined) => void;
};

const ClientForm: FC<ClientFormProps> = ({
  client,
  close,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  handlePackageChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientPackages = useSelector<StoreState, AvailablePackage[]>(state => state.interface.availablePackages);
  const [selectedPackage, setPackage] = useState(client?.boughtPackage);

  const { name, surname, PESEL, additionalNr, email, phone, companyName, address, boughtPackage, payerNote } = client;

  const onSelect = (pkg: any) => {
    setPackage(pkg);
    if (handlePackageChange) handlePackageChange(pkg.id);
  };

  useEffect(() => {
    let found = false;
    if (clientPackages && client?.boughtPackage) {
      clientPackages.map(cp => {
        if (cp.id === client.boughtPackage?.usedCompanyPackageId) {
          found = true;
          onSelect(cp);
        }
      });
    }
    if (!found) {
      setPackage({ name: t('common:select_pkg_choose') });
    }
  }, [clientPackages]);

  useEffect(() => {
    dispatch(availablePackages());
  }, []);

  return (
    <div className="new-member-modal__content">
      <Flex width="fill" justify="space-between">
        <div className="content__split">
          <InputLabel text={t('common:name')} />
          <Input
            className="new-member-modal__input"
            width="fill"
            name="name"
            value={name}
            placeholder={t('common:enter_name')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="content__split">
          <InputLabel text={t('common:surname')} />
          <Input
            className="new-member-modal__input"
            name="surname"
            width="fill"
            value={surname}
            placeholder={t('common:enter_surname')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
      </Flex>
      <Flex width="fill" justify="space-between">
        <div className="content__split">
          <InputLabel text={t('common:pesel')} />
          <Input
            className="new-member-modal__input"
            name="PESEL"
            width="fill"
            value={PESEL}
            placeholder={t('common:pesel')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="content__split">
          <InputLabel text={t('common:additional_number')} />
          <Input
            className="new-member-modal__input"
            name="additionalNr"
            width="fill"
            value={additionalNr}
            placeholder={t('common:enter_number')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
      </Flex>

      <InputLabel text={t('common:street')} />
      <Input
        className="new-member-modal__input"
        name="street"
        width="fill"
        value={address?.street}
        placeholder={t('common:street_example')}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Flex width="fill" justify="space-between">
        <div className="content__split content__postal-code">
          <InputLabel text={t('common:postal_code')} />
          <Input
            className="new-member-modal__input"
            name="postcode"
            width="fill"
            value={address?.postcode}
            placeholder={t('common:enter')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="content__split content__place">
          <InputLabel text={t('common:location')} />
          <Input
            className="new-member-modal__input"
            name="city"
            width="fill"
            error={(touched.city && errors.city) || ''}
            value={address?.city}
            placeholder={t('common:location_example')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
      </Flex>

      <Flex width="fill" justify="space-between">
        <div className="content__split">
          <InputLabel text={t('common:email_address')} />
          <Input
            className="new-member-modal__input"
            name="email"
            width="fill"
            error={(touched.email && errors.email) || ''}
            value={email}
            placeholder={t('common:email_placeholder')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="content__split">
          <InputLabel text={t('common:phone')} />
          <Input
            className="new-member-modal__input"
            name="phone"
            width="fill"
            error={(touched.phone && errors.phone) || ''}
            value={phone}
            placeholder={t('common:phone_placeholder')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
      </Flex>

      <Flex width="fill" justify="space-between">
        <div className="content__split">
          <InputLabel text={t('common:workplace')} />
          <Input
            className="new-member-modal__input"
            name="companyName"
            width="fill"
            value={companyName}
            placeholder={t('common:enter_workplace')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="content__split">
          <InputLabel text={t('common:remarks')} />
          <Input
            className="new-member-modal__input"
            name="payerNote"
            width="fill"
            error={(touched.phone && errors.phone) || ''}
            value={payerNote}
            placeholder={t('common:remarks_placeholder')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
      </Flex>

      <InputLabel text={t('common:active_pkg')} />
      <Select
        defaultValue={selectedPackage?.name /* || t('common:select_pkg_choose') */}
        onSelect={onSelect}
        options={clientPackages}
        value={selectedPackage?.name || ''}
        emptyListPlaceholder={t('common:no_packages_available')}
      />

      <Divider />

      <Flex justify="end">
        <Button
          className="new-member-model__cancel-button"
          variant="ghost"
          height="compact"
          width="auto"
          onClick={close}
        >
          {t('common:cancel')}
        </Button>
        <Button type="submit" width="auto" height="compact" onClick={() => handleSubmit()}>
          {t('common:save')}
        </Button>
      </Flex>
    </div>
  );
};

export default ClientForm;

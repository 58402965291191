import { useCallback, useState } from 'react';

const useToggle = (initialState = false): UseToggle => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => {
    setState(prev => !prev);
  }, []);

  const set = useCallback((value: boolean) => {
    setState(value);
  }, []);

  return [state, { toggle, set }];
};

type UseToggle = [
  boolean,
  {
    toggle: () => void;
    set: (value: boolean) => void;
  }
];

export default useToggle;

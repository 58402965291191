import React, { FC, memo } from 'react';
import { IconButton } from '@mui/material';

import calcSize from 'utils/calcSize';

import Flex from 'components/Flex';
import IconClose from 'assets/images/icon-close.svg';

const FileListItem: FC<Props> = ({ file, onClickRemove }) => {
  return (
    <Flex align="center" justify="space-between" component="li" className="file-list__item">
      <div className="item__file-name">{file.name}</div>
      <div className="item__file-size">{calcSize(file.size)}</div>
      <IconButton size="small" onClick={onClickRemove}>
        <img src={IconClose} />
      </IconButton>
    </Flex>
  );
};

type Props = {
  file: File;
  onClickRemove: () => void;
};

export default memo(FileListItem);

import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import StartLayout from 'components/StartLayout';
import InputLabel from 'components/InputLabel';
import Headline from 'components/Headline';
import Button from 'components/Button';
import Input from 'components/Input';
import useToggleList from 'hooks/useToggleList';
import useResetPassword from './useResetPassword';
import EyeIcon from 'assets/images/icon-eye.png';
import ClosedEyeIcon from 'assets/images/icon-eye-closed.svg';
import './reset-password.scss';

const initialToggles = {
  password: false,
  repeatPassword: false,
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const [{ password, repeatPassword, errors, touched }, { onChange, onBlur, onSubmit }] = useResetPassword();
  const { items: isVisible, toggle } = useToggleList(initialToggles);

  return (
    <div className="reset-password">
      <StartLayout>
        <Headline className="reset-password__headline">{t('login:reset_password')}</Headline>

        <InputLabel text={t('login:new_password')} required />
        <Input
          type={isVisible.password ? 'text' : 'password'}
          value={password}
          name="password"
          width="fill"
          className="reset-password__input"
          placeholder={t('login:new_password_label')}
          error={(touched.password && errors.password) || ''}
          onBlur={onBlur}
          onChange={onChange}
          endIcon={
            <IconButton size="small" onClick={toggle('password')}>
              <img src={isVisible.password ? ClosedEyeIcon : EyeIcon} alt="" />
            </IconButton>
          }
        />

        <InputLabel text={t('login:repeat_password')} required />
        <Input
          type={isVisible.repeatPassword ? 'text' : 'password'}
          value={repeatPassword}
          name="repeatPassword"
          width="fill"
          className="reset-password__input"
          placeholder={t('login:new_password_repeat_label')}
          error={(touched.repeatPassword && errors.repeatPassword) || ''}
          onBlur={onBlur}
          onChange={onChange}
          endIcon={
            <IconButton size="small" onClick={toggle('repeatPassword')}>
              <img src={isVisible.repeatPassword ? ClosedEyeIcon : EyeIcon} alt="" />
            </IconButton>
          }
        />
        <Button className="reset-password__button" width="fill" onClick={onSubmit}>
          {t('login:change_password')}
        </Button>
      </StartLayout>
    </div>
  );
};

export default memo(ResetPassword);

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setNavbarActions, setviewTitle } from 'actions';
import Flex from 'components/Flex';
import useProfile from './useProfile';
import Entry from './Entry';
import './profile.scss';

const Profile = () => {
  const { t } = useTranslation('profile');
  const { profile } = useProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    let name = '';

    if (profile) {
      name = `${profile.name} ${profile.surname}`;
    }

    dispatch(setviewTitle(t('navbar:view_title_profile', { userName: name })));
    dispatch(setNavbarActions({}));
  }, [t, profile, dispatch]);

  if (!profile) {
    return null;
  }

  const { company, phone, login } = profile;
  const { address, email, name, nip, userAttendant } = company;

  return (
    <Flex className="profile">
      <div className="profile__leftside">
        {name && (
          <Entry label={t('label_company')}>
            <p>{name}</p>
          </Entry>
        )}

        {(address?.street || address?.postcode) && (
          <Entry label={t('label_address')}>
            <p>{address?.street}</p>
            <p>
              {address?.postcode} {address?.city}
            </p>
          </Entry>
        )}

        {phone && (
          <Entry label={t('label_phone')}>
            <p>{phone}</p>
          </Entry>
        )}

        {email && (
          <Entry label={t('label_email')}>
            <p>{email}</p>
          </Entry>
        )}

        {nip && (
          <Entry label={t('label_tax_no')}>
            <p>{nip}</p>
          </Entry>
        )}

        {(login || email) && (
          <Entry label={t('label_logged')}>
            <p>{login || email}</p>
          </Entry>
        )}
      </div>

      <div className="profile__rightside">
        <Entry label={t('label_contact')}>
          <p>Lex Secure 24h Opieka Prawna Komar-Komarowski Spółka Komandytowa</p>

          <div className="entry__section">
            <p>Al. Niepodległości 723/6</p>
            <p>81-853 Sopot</p>
          </div>

          <div className="entry__section">
            <p className="entry--with-prefix entry--phone">
              <a href="tel:+48 58 500 59 00">+48 58 500 59 00</a>
            </p>
          </div>

          <p className="entry--with-prefix entry--email">
            <a href="mailto:biuro@opiekaprawna.pl">biuro@opiekaprawna.pl</a>
          </p>
        </Entry>

        {(userAttendant?.name || userAttendant?.surname) && (
          <Entry label={t('label_user_attendant')}>
            <div className="entry__section">
              <p>
                <span>{userAttendant?.name}</span> <span>{userAttendant?.surname}</span>
              </p>
              {userAttendant?.position && <p>{userAttendant?.position}</p>}
            </div>
            {userAttendant?.phone && (
              <div className="entry__section">
                <p className="entry--with-prefix entry--phone">{userAttendant?.phone}</p>
              </div>
            )}
            {userAttendant?.email && (
              <div className="entry__section">
                <p className="entry--with-prefix entry--email">{userAttendant?.email}</p>
              </div>
            )}
          </Entry>
        )}
      </div>
    </Flex>
  );
};

export default Profile;

import React, { FC } from 'react';
import clsx from 'clsx';

import { SelectOptionsProps } from '../Select.types';

const SelectOptions: FC<SelectOptionsProps> = ({ emptyListPlaceholder, options, classes, onSelect }) => {
  const handleSelect = (option: any) => () => onSelect(option);

  const optionItemClasses = clsx('select__option-item', classes?.optionItem);
  const optionEmptyPlaceholder = clsx('select__option-item', 'select__option-item--empty');
  const optionsClasses = clsx('select__options');

  return (
    <ul className={optionsClasses}>
      {options?.length > 0 ? (
        options.map((option: string | any) => (
          <li key={option.id || option} className={optionItemClasses} onClick={handleSelect(option)}>
            {option.name || option}
          </li>
        ))
      ) : (
        <li className={optionEmptyPlaceholder}>{emptyListPlaceholder}</li>
      )}
    </ul>
  );
};

export default SelectOptions;

import React, { FC, memo, ReactNode } from 'react';
import clsx from 'clsx';
import Flex from 'components/Flex';
import './table-layout.scss';

const TableLayout: FC<Props> = ({ additionalcontent, children, afterTable, beforeTable, className = '' }) => {
  const classes = clsx('table-layout', className);
  const containerClasses = clsx('table-layout__table-container', {
    'with-after': afterTable,
    'with-before': beforeTable,
  });

  return (
    <Flex className={classes} justify="space-between" direction="column" width="fill">
      <Flex direction="column" width="fill" className="table-layout__table-wrapper" justify="start">
        {beforeTable}
        <div className={containerClasses}>
          <div className="table-layout__table">{children}</div>
        </div>
        {afterTable}
      </Flex>

      <Flex width="fill" justify="end" className="table-layout__pagination">
        {additionalcontent}
      </Flex>
    </Flex>
  );
};

type Props = {
  additionalcontent?: ReactNode;
  className?: string;
  children?: ReactNode;
  beforeTable?: ReactNode;
  afterTable?: ReactNode;
};

export default memo(TableLayout);

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';

export type NavbarActionsTypes = {
  fn?: () => void;
  label?: 'newCase' | 'goBack';
};

const NavActions: FC<NavbarActionsTypes> = ({ fn, label }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const goBackAction = () => {
    navigate(-1);
  };

  const executeAction = () => {
    fn && fn();
  };

  switch (label) {
    case 'goBack':
      return (
        <Button onClick={goBackAction} width="auto" variant="ghost">
          {t('go_back')}
        </Button>
      );

    case 'newCase':
      return (
        <div className="navbar__action-wrapper">
          <Button onClick={goBackAction} width="auto" variant="ghost">
            {t('cancel')}
          </Button>
          <Button onClick={executeAction} className="navbar__add-case" width="auto">
            {t('send_application')}
          </Button>
        </div>
      );

    default:
      return null;
  }
};

export default NavActions;

import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from 'store';

import Flex from 'components/Flex';
import NavActions from './NavActions';
import { NavbarActionsTypes } from './NavActions/NavActions';

import './navbar.scss';

const Navbar = () => {
  const viewTitle = useSelector<StoreState, string>(state => state.interface.viewTitle);
  const navbarActions = useSelector<StoreState, NavbarActionsTypes>(state => state.interface.navbarActions);

  return (
    <nav className="navbar">
      <Flex align="center" className="navbar__view-title">
        {navbarActions?.label === 'goBack' && <NavActions {...navbarActions} />}
        {viewTitle}
      </Flex>
      {navbarActions?.label !== 'goBack' && <NavActions {...navbarActions} />}
    </nav>
  );
};

export default Navbar;

import { t } from 'i18next';

const validatePassword = (password?: string, key?: string) => {
  let errors = {};

  if (!password) {
    errors = { ...errors, [key || 'password']: t('common:cannot_be_empty') };
  } else if (password?.length < 8) {
    errors = { ...errors, [key || 'password']: t('login:minimum_password_length', { length: 8 }) };
  }

  return errors;
};

export default validatePassword;

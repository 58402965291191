import React, { FC, memo, useEffect } from 'react';
import { t } from 'i18next';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';

import { addClient, editClient, getClient, enqueueSnackbar } from 'actions';
import { BoughtPackage, Notification } from 'reducers';
import type { Client, ClientAddress } from 'api/api';

import ClientForm from './items/ClientForm';

import './new-member-modal.scss';

const NewMemberModal: FC<Props> = ({ open, close, fetchClients }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validate = (values: State) => {
    if (
      !!values?.name ||
      !!values?.surname ||
      !!values?.PESEL ||
      !!values?.additionalNr ||
      !!values?.street ||
      !!values?.postcode ||
      !!values?.city ||
      !!values?.email ||
      !!values?.phone ||
      !!values?.companyName ||
      !!values?.payerNote
    ) {
      return true;
    }
    dispatch(enqueueSnackbar(new Notification('empty_form', 'error')));
    return false;
  };

  const formik = useFormik<State>({
    initialValues: {
      name: '',
      surname: '',
      PESEL: '',
      additionalNr: '',
      street: '',
      postcode: '',
      city: '',
      email: '',
      phone: '',
      companyName: '',
      packageId: undefined,
      activePackage: undefined,
      payerNote: '',
    },
    onSubmit: values => {
      const param = new URLSearchParams(document.location.search);
      const clientId = param.get('clientId');

      const clientData = {
        ...(values.name && { name: values.name }),
        ...(values.surname && { surname: values.surname }),
        ...(values.PESEL && { PESEL: values.PESEL }),
        ...(values.additionalNr && { additionalNr: values.additionalNr }),
        ...(values.email && { email: values.email }),
        ...(values.phone && { phone: values.phone }),
        ...(values.companyName && { companyName: values.companyName }),
        ...(values.payerNote && { payerNote: values.payerNote }),
        address: {
          ...(values.city && { city: values.city }),
          ...(values.street && { street: values.street }),
          ...(values.postcode && { postcode: values.postcode }),
        },
      };

      if (clientId && validate(values)) {
        dispatch(
          editClient({ ...clientData, clientId, packageId: values.packageId }, () => {
            dispatch(fetchClients());
            close();
          })
        );
      } else if (validate(values)) {
        dispatch(
          addClient({ ...clientData, packageId: values.packageId }, () => {
            dispatch(fetchClients());
            close();
          })
        );
      }
    },
  });

  const { handleChange, handleBlur, handleSubmit, touched, errors } = formik;
  const {
    name,
    surname,
    PESEL,
    additionalNr,
    street,
    postcode,
    city,
    email,
    phone,
    companyName,
    packageId,
    activePackage,
    payerNote,
  } = formik.values;

  const handlePackageChange = (pkgId: number | undefined) => {
    if (pkgId) formik.values.packageId = pkgId;
  };

  const getFormValues = (formValues: Client | ClientAddress) => {
    const { setFieldValue } = formik;
    for (const [key, value] of Object.entries(formValues)) {
      if (value && typeof value === 'object' && key === 'address') getFormValues(value);
      setFieldValue(key, value);
    }
  };

  useEffect(() => {
    const param = new URLSearchParams(document.location.search);
    const clientId = param.get('clientId');
    if (clientId) dispatch(getClient({ id: clientId }, response => getFormValues(response?.data?.data)));
    return () => navigate({ pathname: '/clients' });
  }, []);

  const clientData = {
    name,
    surname,
    PESEL,
    additionalNr,
    email,
    phone,
    companyName,
    boughtPackage: activePackage,
    payerNote,
    address: {
      city,
      street,
      postcode,
    },
  };

  return (
    <Modal open={open} onClose={close}>
      <Box className="new-member-modal">
        <ClientForm
          client={clientData}
          close={close}
          touched={touched}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          handlePackageChange={handlePackageChange}
        />
      </Box>
    </Modal>
  );
};

type Props = {
  open: boolean;
  close: () => void;
  fetchClients: () => void;
};

type State = {
  name: string;
  surname: string;
  PESEL: string;
  additionalNr: string;
  street: string;
  postcode: string;
  city: string;
  email: string;
  phone: string;
  companyName: string;
  packageId?: number;
  activePackage?: BoughtPackage;
  payerNote?: string;
};

export default memo(NewMemberModal);

import React, { FC, memo } from 'react';
import FileDownload from 'components/FileDownload';

const InvoiceFile: FC<Props> = ({ id, name }) => {
  return (
    <FileDownload
      id={id}
      className="invoices__file"
      title={name}
      anchorClass="invoices__file-anchor"
      iconClass="invoices__file-icon"
    />
  );
};

type Props = {
  id: number;
  name: string;
};

export default memo(InvoiceFile);

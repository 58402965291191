import React, { FC, memo, ReactElement } from 'react';
import clsx from 'clsx';
import './entry.scss';

const Entry: FC<Props> = ({ label, children, className = '' }) => {
  const classes = clsx('entry', className);

  return (
    <div className={classes}>
      <h4 className="entry__label">{label}</h4>
      <div className="entry__value">{children}</div>
    </div>
  );
};

type Props = {
  label: string;
  className?: string;
  children: ReactElement | ReactElement[];
};

export default memo(Entry);

import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { availablePackages, addPackage, deletePackage } from 'actions';
import type { Client } from 'reducers';

import Button from 'components/Button';
import Flex from 'components/Flex';

import { ReactComponent as CloseIcon } from 'assets/images/icon-close.svg';
import '../delete-package-modal.scss';

type Props = {
    client?: Client;
    open: boolean;
    close: () => void;
    fetchClients: () => void;
    clientName: string;
    clientSurname: string;
};

const DeletePackageModal: FC<Props> = ({ client, open, close, fetchClients, clientName, clientSurname }) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const [selectedPackage, setPackage] = useState(client?.boughtPackage);

    useEffect(() => {
        dispatch(availablePackages());
    }, []);

    const removePackage = () => {
        if (client?.id && selectedPackage?.id) {
            setPackage({});
            dispatch(
                deletePackage({ clientId: client.id, packageId: selectedPackage.id }, () => {
                    dispatch(fetchClients());
                    close();
                })
            );
        }
    };

    return (
        <Modal sx={{}} open={open} onClose={close}>
            <Flex direction='column' justify="center" align="center" className="package-selector-modal" >
                <Box >
                    <div className="delete-package-modal__content">
                        <Flex>
                            <button className="delete-package-modal__close" onClick={close}>
                                <CloseIcon />
                            </button>
                            <p className='delete-package-modal-text'>
                                Czy napewno chcesz usunąć pakiet klientowi {clientName} {clientSurname ? clientSurname : ''}?
                            </p>
                        </Flex>
                        <Flex justify='end' className='delete-package-modal-button-wrapper' >
                            <Button className='delete-package-modal-button' onClick={close} variant="ghost" width={'auto'} >
                                {t('cancel')}
                            </Button>
                            <Button className='delete-package-modal-button' onClick={removePackage} width={'auto'} >
                                {t('remove_pkg')}
                            </Button>
                        </Flex>
                    </div>
                </Box>
            </Flex>
        </Modal>
    );
};

export default DeletePackageModal;

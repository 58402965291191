import { BoughtPackage } from 'reducers';
import Endpoint from './Endpoint';

export default {
  addClient: new Endpoint<AddClientBody>({
    path: '/clients/group/client',
    method: 'POST',
  }),
  addPackage: new Endpoint<AddPackageBody>({
    path: '/package/clients/group',
    method: 'POST',
  }),
  deletePackage: new Endpoint<DeletePackageBody>({
    path: '/package/clients/group/{clientId}/{packageId}',
    method: 'DELETE',
  }),
  editClient: new Endpoint<EditClientBody>({
    path: '/clients/group/client/{clientId}',
    method: 'PUT',
  }),
  getClient: new Endpoint<GetClientBody>({
    path: '/clients/group/client/{clientId}',
    method: 'GET',
  }),
  availablePackages: new Endpoint<AvailablePackagesBody>({
    path: '/package/clients/group/available',
    method: 'GET',
  }),
  getFile: new Endpoint<GetFileBody>({
    path: '/group/client/files/{fileId}',
    method: 'GET',
  }),
  login: new Endpoint<LoginBody>({
    path: '/clients/group/login',
    method: 'POST',
  }),
  clients: new Endpoint<ClientsListBody>({
    path: '/client/group/search',
    method: 'GET',
  }),
  messages: new Endpoint<MessagesListBody>({
    path: '/company/group/client',
    method: 'GET',
  }),
  profile: new Endpoint({
    path: '/client/group/profile',
    method: 'GET',
  }),
  requestResetPassword: new Endpoint<RequestResetPasswordBody>({
    path: '/clients/password/reset',
    method: 'POST',
  }),
  resetPassword: new Endpoint<ResetPasswordBody>({
    path: '/clients/password/reset/change',
    method: 'POST',
  }),
  changePassword: new Endpoint<ChangePasswordBody>({
    path: '/clients/password/change',
    method: 'POST',
  }),
};

type ClientsFilter = {
  limit: number;
  offset: number;
};

export type ClientAddress = {
  city?: string;
  street?: string;
  postcode?: string;
};

export type Client = {
  name?: string;
  surname?: string;
  PESEL?: string;
  additionalNr?: string;
  email?: string;
  phone?: string;
  companyName?: string;
  address?: ClientAddress;
  boughtPackage?: BoughtPackage;
  payerNote?: string;
};

export type AvailablePackagesBody = {
  id?: number;
  activeFrom?: string;
  activeTo?: any;
  companyId?: number;
  name?: string;
  amount?: number;
};

export type AddPackageBody = {
  clientId: number;
  packageId: number;
};

export type DeletePackageBody = {
  clientId: number;
  packageId: number;
};

export type AddClientBody = Client & { packageId: number | undefined };

export interface EditClientBody extends Client {
  clientId?: string;
  packageId?: number;
}

export type GetClientBody = {
  id: string;
};

export type GetFileBody = {
  fileId: number;
  responseType: string;
};

export type LoginBody = {
  login: string;
  password: string;
};

export type RequestResetPasswordBody = {
  email: string;
};
export type ResetPasswordBody = {
  hash: string;
  newPassword: string;
  email: string;
};
export type ChangePasswordBody = {
  password: string;
  newPassword: string;
};

export type ClientsListBody = {
  params: {
    onlyActive?: boolean;
    search?: string;
    filter?: ClientsFilter;
    yearMonth?: string;
  };
};

export type MessagesListBody = any;

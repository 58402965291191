import React, { FC, useEffect, useState, memo } from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import ClickAway from 'components/ClickAway';

import { months, Month } from './months';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar.svg';
import './month-selector.scss';

type Props = {
  value?: Month;
  onSelect: (month: Month) => void;
};

const date = new Date();
const defaultMonth = {
  name: months[date?.getMonth()]?.name,
  number: months.findIndex(element => element?.name === months[date?.getMonth()]?.name),
  year: date.getFullYear(),
};

const MonthSelector: FC<Props> = ({ value, onSelect }) => {
  const { t } = useTranslation('common');
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selectedOption, setOption] = useState<Month | any>(value?.name ? value : defaultMonth);
  const [monthsList] = useState(months);

  const currentIndex = months.findIndex(element => element?.name === selectedOption?.name);

  const [year, setYear] = useState<number>(date.getFullYear());
  const [prevMonthsList, setPrevMonthList] = useState<Month[]>(months.filter(el => el?.number < currentIndex + 1));
  const [nextMonthsList, setNextMonthList] = useState<Month[]>(months.filter(el => el.number > currentIndex + 1));

  useEffect(() => {
    setPrevMonthList(months.filter(el => el?.number < currentIndex + 1));
    setNextMonthList(months.filter(el => el.number > currentIndex + 1));
  }, [currentIndex, value?.name]);

  const optionItemClasses = clsx('select__option-item');
  const optionsClasses = clsx('select__options');
  const controllerClasses = clsx('select__controller');
  const iconClasses = clsx('controller__icon', { 'controller__icon--rotated': isVisible });

  const getDateSet = () => {
    let prev: Month[] = [];
    let next: Month[] = [];

    if (!selectedOption?.name) return;

    const curr: Month = selectedOption;
    curr.year = year;
    curr.currentMonth = true;

    if (prevMonthsList.length <= 6) {
      const prevMonthsToAdd = [...monthsList]
        .reverse()
        .splice(0, 6 - prevMonthsList.length)
        .reverse();
      const lastyearMonths = prevMonthsToAdd.map(el => ({ ...el, year: year - 1 }));
      const prevMonthsWithYear = prevMonthsList.map((el: Month) => ({ ...el, year }));
      prev = [...lastyearMonths, ...prevMonthsWithYear];
    }
    if (prevMonthsList.length > 6) {
      const prevMonthsToAdd = [...prevMonthsList].reverse().splice(0, 6).reverse();
      const prevMonthsWithYear = prevMonthsToAdd.map((month: Month) => ({ ...month, year }));
      prev = [...prevMonthsWithYear];
    }
    if (nextMonthsList.length <= 6) {
      const nextMonthsToAdd = [...monthsList].splice(0, 6 - nextMonthsList.length);
      const nextyearMonths = nextMonthsToAdd.map(el => ({ ...el, year: year + 1 }));
      const nextMonthsWithYear = nextMonthsList.map((el: Month) => ({ ...el, year }));
      next = [...nextMonthsWithYear, ...nextyearMonths];
    }
    if (nextMonthsList.length > 6) {
      const nextMonthsToAdd = [...nextMonthsList].splice(0, 6);
      const nextMonthsWithYear = nextMonthsToAdd.map((month: Month) => ({ ...month, year }));
      next = [...nextMonthsWithYear];
    }

    return [...prev, curr, ...next];
  };

  const handleMenuOpen = () => {
    setVisible(true);
  };

  const handleMenuClose = () => {
    setVisible(false);
  };

  const handleSelect = (month: Month) => () => {
    if (month.year !== year) setYear(month.year);
    onSelect(month);
    setOption(month);
    handleMenuClose();
  };

  return (
    <div className="month-selector">
      <div className="select__controller-wrapper">
        <button type="button" className={controllerClasses} onClick={handleMenuOpen}>
          <CalendarIcon />
          <div className="month-selector__label">
            <span>{t(`month_${selectedOption?.name}`)}</span>
            <span>{selectedOption?.year}</span>
          </div>
          <ChevronIcon className={iconClasses} />
        </button>
      </div>

      <ClickAway onClickAway={handleMenuClose}>
        <CSSTransition in={isVisible} timeout={200} classNames="options-fade-animation" unmountOnExit>
          <ul className={optionsClasses}>
            {getDateSet()?.map((month: Month) => {
              const monthId = `${month.number}${month.name}${month.year}`;
              return (
                <li
                  key={monthId}
                  className={`${optionItemClasses} ${month.currentMonth ? 'select__option-item--current' : ''}`}
                  onClick={handleSelect(month)}
                >
                  <span className="option-item--regular">{t(`month_${month.name}`)}</span>
                  <span className="option-item--faded">{month.year}</span>
                </li>
              );
            })}
          </ul>
        </CSSTransition>
      </ClickAway>
    </div>
  );
};

export default memo(MonthSelector);

import React, { FC, memo } from 'react';
import clsx from 'clsx';
import Flex from 'components/Flex';
import './input-label.scss';

const InputLabel: FC<Props> = ({ text, link, href, required = false }) => {
  const textClasses = clsx('input-label__text', {
    required,
  });

  return (
    <Flex width="fill" justify="space-between" align="center" className="input-label">
      <div>
        <span className={textClasses}>{text}</span>
      </div>
      <div>
        {link && href && (
          <a className="input-label__link" href={href}>
            {link}
          </a>
        )}
      </div>
    </Flex>
  );
};

type Props = {
  text: string;
  link?: string;
  href?: string;
  required?: boolean;
};

export default memo(InputLabel);
